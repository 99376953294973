import {Component, Provide, Vue} from 'vue-property-decorator';
import {OauthBindConfirmApi} from "@/network/modules/mine";
import {OauthBindConfirmReq} from "@/interface/res/mine";

@Component({
  components: {
  },
})
export default class WeChatLogin extends Vue {
  @Provide() info: {
    accountAvatar: string,
    accountId: string,
    accountNickName: string,
    canBind: string,
    unionid: string,
    wechatAvatar: string,
    wechatNickName: string,
  } = {
    accountAvatar: "",
    accountId: "",
    accountNickName: "",
    canBind: "",
    unionid: "",
    wechatAvatar: "",
    wechatNickName: ""
  }
  @Provide() canBind:string = ""
  @Provide() msgType:string = ""
  @Provide() loading:boolean = false

  mounted(){
    const _this:any = this;
    if(!_this.$base.isNull(_this.$route.query)){
      _this.info = _this.$route.query
      _this.$nextTick(()=>{
        _this.canBind = _this.info.canBind
      })
    }
  }

  /**
   * @Author HS
   * @Date 2021/8/31 5:38 下午
   * @Description: 确认绑定
   * @Params: null
   * @Return: null
  */
  async OauthBindConfirmFn(){
    const _this:any = this;
    _this.loading = true
    let params:OauthBindConfirmReq = {
      accountId:_this.info.accountId,
      unionid:_this.info.unionid,
    }
    const res = await OauthBindConfirmApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { code , msg } = res
    if(code != 200 ){
      _this.msgType = msg;
      return
    }

    _this.msgType = '0';
    _this.closeFn()
  }

  /**
   * @Author HS
   * @Date 2021/8/31 5:37 下午
   * @Description: 关闭窗口
   * @Params: null
   * @Return: null
  */
  closeFn(){
    window.close();
    return
  }

}
